<div class="container">

	<div class="form-row mt-3">
		<div class="col-auto">
			<div class="form-group-inline">
				<Button
						label="Добавить новость"
						:attributes="{class:['btn-blue', 'h36', 'inline', 'ml-40']}"
						@click.prevent="edit"
				/>
			</div>
		</div>
	</div>

	<Grid
		:columns="[
			{
				name: 'id',
				hidden: true,
			},
			{
				name: 'news',
				label: 'Новость',
				width: '70%',
			},
			{
				name: 'disabled',
				label: 'Не отображать',
				width: '10%',
			},
			{
				name: 'due_date_to',
				label: 'Действует до',
				width: '10%',
			},
			{
				label: 'Действия',
				width: '10%',
			},
		]"
		:formatters="{
			disabled: (value) => {
				return (value) ? 'Да' : 'Нет';
			},
			due_date_to: (date) =>{
				return (date) ? date.toLocaleDateString('ru-RU') : 'Без ограничений';
			}
		}"
		:data="news"
		:onEdit="edit"
		:onRemove="remove"
	></Grid>

	<teleport to="body">
		<Modal
				v-if="showModal"
				@onOk="showModal = false"
				@on-close="showModal = false"
				:buttons="[]"
		>
			<template v-slot:header>
				<h2 class="h1">Редактирование новости</h2>
			</template>

			<template v-slot:body>
				<form>
					<div class="modal-section">
						<div v-if="sendProcess" class="preloader pv-5"></div>
						<template v-else>
							<div class="form-row">
								<div class="col-12  mb-2">
									<Editor
                                        :api-key="tinyMCEApiKey"
                                        :init="{
											menubar: false,
											relative_urls: true,
											convert_urls: false,
											statusbar: false,
											plugins: [
												'advlist autolink lists link image charmap print preview anchor',
												'media table paste'
											],
											toolbar:
												'link undo redo | bold italic backcolor | \
												alignleft aligncenter alignright alignjustify | \
												bullist numlist outdent indent link'
										}"
										label="Новость"
										v-model.trim="editNewsData.news"
									/>
                                    <error v-if="errors.news.length" :message="errors.news"/>
								</div>

								<div class="col-12 mb-2">
									<datepicker
											v-model="editNewsData.due_date_to"
											class="picker"
											placeholder="Дата до"
											:locale="dpSettings.locale"
                                            input-format="dd.MM.yyyy"
									/>
                                    <error v-if="errors.dueDateTo.length" :message="errors.dueDateTo"/>
								</div>

								<div class="col-12 mb-2">
									<FormCheckbox
											label="Не отображать"
											v-model="editNewsData.disabled"
									></FormCheckbox>
								</div>

							</div>
							<div class="row-col">
								<div class="col-12">
									<Button
                                        :disabled="!isValid"
                                        @click.prevent="save"
										label='Сохранить'
										:attributes="{ class: 'btn-green' }"
                                    />
								</div>
							</div>
						</template>
					</div>
				</form>
			</template>
		</Modal>
	</teleport>
</div>
