<template src="./templates/newsEditPage.html"></template>

<script>

import PageHeader from '@c/Header';
import Breadcrumbs from '@c/Breadcrumbs';
import PageTitle from '@c/PageTitle';
import NewsGrid from './components/newsGrid';

export default {
    name: 'QuestionsBenefits',
    components: {
        PageHeader,
        Breadcrumbs,
        PageTitle,
        NewsGrid
    },
    props: {
        id: {
            type: String,
            default() {
                return null;
            },
        },
    }
};
</script>
